<template>
    <v-hover v-slot:default="{hover}">
        <v-card class="card off mx-0 mb-1" :class="{ 'card_hover' : hover}"
                :elevation="hover ? 4 : 2" style="cursor: pointer;" @click="dialog_gold_price = true">

            <v-card-text class="card-state pa-1" :class="state">
                <!--HEADER-->
                <v-row v-if="true" no-gutters justify="center">
                    <v-col cols="6" class="pb-0">
                        <span> {{item.agreementId}}</span>
                        <span> • </span>
                        <span class="gold--text font-weight-medium">{{item.customer}}</span>
                    </v-col>
                    <v-col cols="6" align="right">
                        <span class="gold white--text rounded px-3 font-weight-medium">
                            {{ $t('gold_deposit.type.' + item.type) }}
                        </span>
                    </v-col>
                    <v-col cols="12">
                        <v-divider class="gold lighten-1"></v-divider>
                    </v-col>
                </v-row>

                <!--DATA-->
                <v-row justify="center" align="center" class="" no-gutters>
                    <!--KEYS-->
                    <v-col v-if="true" cols="12">
                        <v-row justify="center">
                            <v-col v-for="v in keys" :key="v.value" xl="0" md="0">
                                <v-row no-gutters justify="center">
                                    <span class="caption gold--text">{{ g_i18_keys(v.value) }}</span>
                                </v-row>
                                <v-row no-gutters justify="center">
                                    <v-col cols="12" align="center">
                                        <v-row v-if="v.value === 'payment'">
                                            <v-col cols="3" align="right">
                                                <v-icon v-if="hover" small color="gold">mdi-pencil-circle-outline</v-icon>
                                            </v-col>
                                            <v-col cols="6">
                                                <span :class="{'gold white--text rounded px-2 font-weight-bold' : hover}">
                                                    {{gold_price_card}} €
                                                </span>

                                            </v-col>
                                            <v-col cols="3"></v-col>

                                        </v-row>
                                        <span v-else-if="v.value === 'goldAmount'" class="">
                                            {{ item[v.value] }}<span> {{ $t('purchase_reports.dialog.pieces') }}</span>
                                        </span>
                                        <span v-else class="gold--text font-weight-bold">{{g_parsePrice(parseFloat(item.payment * item.goldAmount))}}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-dialog
                v-model="dialog_gold_price"
                persistent
                max-width="540"
            >
                <v-alert v-if="dialog_gold_price" class="my-0 font-weight-medium" border="left">
                    <!--{{item}}-->
                    <v-alert type="info" color="gold" text dense class="caption">
                        {{ item.agreementId }} • {{ item.customer }} • <strong>{{ $t('gold_deposit.type.' + item.type) }} {{ item.goldAmount }} ks</strong><br>
                        <!--<span class="gold-price" v-if="item.goldAmount > 1" v-html="$t('purchase_reports.dialog.prediction', {pieces: item.goldAmount, totalPayment: g_isDefined(g_parsePrice(gold_price * item.goldAmount))})"/>-->
                    </v-alert>
                    <v-row align="center">
                        <v-col class="grow">
                            <span class="body-2">{{ $t('purchase_reports.table_keys.payment') }} - <strong><span class="gold--text">{{ $t('gold_deposit.type.' + item.type) }}</span></strong></span>
                            <v-form @submit.prevent="updateGoldPrice" id="form" ref="form">
                                <v-text-field class="gold-price" suffix="€"
                                              v-model="gold_price" color="gold"
                                              outlined dense
                                              persistent-hint

                                              v-validate="'required|double'"
                                              data-vv-name="gold_price"
                                              :error-messages="errors.collect('gold_price')"
                                />
                            </v-form>

                        </v-col>
                        <v-col class="shrink">
                            <v-btn small dense depressed class="gold white--text mb-4" width="128" @click="updateGoldPrice">
                                {{ $t('common.ok') }}
                            </v-btn>
                            <v-btn small dense text outlined width="128"
                                   @click="dialogClose">
                                {{ $t('common.cancel') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-alert>

            </v-dialog>
        </v-card>
    </v-hover>
</template>

<script>
import {updateGoldPurchasePriceForReport} from "@/api/purchase";
import {Utils} from "@/services/utils";

export default {
    name: "GoldPurchaseOnceCard",
    props: {
        item: Object,
        keys: undefined,
        state: undefined
    },
    data: () => ({
        dialog_gold_price: false,
        gold_price: undefined,
        gold_price_card: undefined,
        card: { cols: 6, xs: 6, sm: 4, md: 1, align: 'center', class: 'my-2 full-cols'}
    }),
    created() {
        this.$validator.extend('double', {
            validate: (value) => {
                return (/^[0-9]*[.,]?[0-9]{0,2}$/g.test(value));
            }
        });

        this.gold_price = Utils.parsePriceValue(this.item.payment);
        this.gold_price_card = this.gold_price
    },
    computed: {
        layout() {
            return this.card;
        }
    },
    methods: {
        updateGoldPrice() {
            this.$validator.validate('gold_price')
                .then((result)=>{
                    if(result) {
                        let new_price = this.gold_price.toString();
                        new_price = parseFloat(new_price.replace(',', '.'));
                        this.gold_price = Utils.parsePriceValue(new_price);
                        this.gold_price_card = this.gold_price
                        updateGoldPurchasePriceForReport(this.item.report_id, this.item.id, {value: new_price})
                            .then(()=>{
                                this.dialog_gold_price = false;
                                this.$root.$emit('notification', {
                                    type: 'success',
                                    icon: 'update',
                                    msg: 'notification.update_report_gold_price'
                                });
                            }).catch(()=>{
                                this.$root.$emit('notification', {
                                    type: 'error',
                                    icon: 'server_error',
                                    msg: 'notification.server_error'
                                });
                            })
                    } else {
                        this.$root.$emit('notification', {
                            type: 'error',
                            icon: 'error',
                            msg: 'notification.gold_price_error'
                        });
                    }
                })

        },
        dialogClose() {
            this.dialog_gold_price = false;
            this.validatorRefresh();
            this.gold_price = Utils.parsePriceValue(this.item.payment);
        },
        validatorRefresh() {

            //let vee = this.$validator;
            this.$validator.pause();

            this.$nextTick(() => {
                this.$validator.errors.clear();
                this.$validator.fields.items.forEach(f => f.reset());
                this.$validator.fields.items.forEach(f => this.errors.remove(f));
                this.$validator.resume();
            });
        },
    }
}
</script>

<style>
    .gold-price input {
        text-align: right;
    }
    .gold-price .key_1 {
        font-weight: bold;
    }
    .gold-price .key_2 {
        font-weight: bold;
        background-color: var(--v-gold-base);
        color: white;
        border-radius: 4px;
        padding: 0 8px;
    }
</style>